import { Link } from "@mui/material";
import { Link as domLink } from "react-router-dom";

const MuiRouterLink = ({ to, text }) => {
  return (
    <Link
      to={to}
      component={domLink}
      // テーブル中にリンクを使用する場合、行の選択が行われないようにするため、stopPropagation()を使用する
      onClick={(e) => e.stopPropagation()}
    >
      {text}
    </Link>
  );
};
export default MuiRouterLink;
