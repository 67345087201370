import { combineReducers, configureStore, createSlice } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
// ローディング表示管理
const loadingSlice = createSlice({
  name: "loadingManager",
  initialState: { loadingArray: [] },
  reducers: {
    start(state, action) {
      state.loadingArray = [...state.loadingArray, action.payload];
    },
    end(state, action) {
      state.loadingArray = state?.loadingArray?.filter(
        (loading) => loading !== action.payload
      );
    },
  },
});

// メッセージトースト表示管理
const messageSlice = createSlice({
  name: "messageManager",
  initialState: { message: null, type: null },
  reducers: {
    setError(state, action) {
      state.message = action.payload;
      state.type = "error";
    },
    setSuccess(state, action) {
      state.message = action.payload;
      state.type = "success";
    },
    remove(state) {
      state.message = null;
      state.type = null;
    },
  },
});

// ロール管理(使用中のロール)
const roleSlice = createSlice({
  name: "roleManager",
  initialState: { role: null },
  reducers: {
    setRole(state, action) {
      state.role = action.payload;
    },
  },
});

// ロール管理(保持しているすべてのロール)
const rolesSlice = createSlice({
  name: "rolesManager",
  initialState: { roles: null },
  reducers: {
    setRoles(state, action) {
      state.roles = action.payload;
    },
  },
});

// 選択されたクライアントグループ
const dashboardStateSlice = createSlice({
  name: "dashboardStateManager",
  initialState: {
    clientGroup: null,
    targetPeriodId: null,
    companyIdList: [],
  },
  reducers: {
    setDashboardState(state, action) {
      state.clientGroup = action.payload.clientGroup ?? state.clientGroup;
      state.targetPeriodId =
        action.payload.targetPeriodId ?? state.targetPeriodId;
      state.companyIdList = action.payload.companyIdList ?? state.companyIdList;
    },
  },
});

const rootReducer = combineReducers({
  loading: loadingSlice.reducer,
  message: messageSlice.reducer,
  role: roleSlice.reducer,
  roles: rolesSlice.reducer,
  dashboardState: dashboardStateSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["dashboardState"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
export const loadingActions = loadingSlice.actions;
export const messageActions = messageSlice.actions;
export const roleActions = roleSlice.actions;
export const rolesActions = rolesSlice.actions;
export const dashboardStateActions = dashboardStateSlice.actions;
