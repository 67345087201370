import { AddCircleRounded, Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useApi, { httpClient } from "../../hooks/useApi";
import FormDialog from "../FormDialog";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array = [], comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    disableDelete,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow key="header">
        <TableCell padding="checkbox" key="head-checkbox">
          {!disableDelete && (
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          )}
        </TableCell>

        {headCells.map((headCell) => {
          return (
            headCell.visible && (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{ minWidth: headCell.minWidth }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
          );
        })}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function AddEditDialogTable({
  getPath, //データ取得APIパス
  deletePath, //データ削除APIパス
  rowMapper = (data) => data?.data,
  headCells,
  editDialogContent,
  listName,
  defaultSelectedRows = [], //デフォルトで選択する行
  _setRows = () => {}, //データ一覧を親から参照したい場合のみ使用
  _setSelected = () => {}, //選択された行を親から参照したい場合のみ使用
  disableAdd = false,
  disableEdit = false,
  disableDelete = false,
  disablePagination = false,
  disableDialog = false,
  readOnlyDialog = false,
  enableKakuninButton = false,
  dialogWidth = "sm",
  dialogProps = {},
  forceUpdate = 0, // データの再描画用
  defaultRows,
  postSubmitHandler = () => {},
}) {
  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [targetData, setTargetData] = useState({});
  const [fetchResult, setFetchResult] = useState({});
  const { connect } = useApi();

  // ロールの取得
  const role = useSelector((state) => state.role.role);

  // API GET
  const fetchData = async () => {
    let tempRows;
    if (!!defaultRows) {
      // デフォルトデータがセットされた場合はフェッチしない
      setRows(defaultRows);
      tempRows = defaultRows;
    } else if (
      getPath?.indexOf("=&") > -1 ||
      getPath?.endsWith("=") ||
      getPath?.indexOf("=undefined&") > -1 ||
      getPath?.endsWith("=undefined")
    ) {
      // クエリパラメータが空の場合はフェッチせず行をクリアする
      setRows([]);
      tempRows = [];
    } else {
      // データ取得
      const result = await connect(() => {
        return httpClient.get(getPath);
      });
      setRows(rowMapper(result?.data, role));
      if (_setRows) _setRows(rowMapper(result?.data, role));
      setFetchResult(result?.data);
      tempRows = result?.data?.data;
    }

    // デフォルト選択行がある場合は選択状態にする
    const selectedTargetRows = tempRows
      ?.map((row) => row.id)
      ?.filter((id) => defaultSelectedRows.includes(id));
    if (selectedTargetRows?.length === 0) {
      handleSelectAllClick({ target: { checked: false } });
    } else {
      updateSelection(selectedTargetRows);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRows, getPath, forceUpdate, role]);

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {props?.title}
          </Typography>
        )}

        {numSelected > 0 &&
          !disableDelete &&
          // 削除不可データが含まれる場合、削除ボタンは表示しない
          rows
            .filter((row) => selected.indexOf(row.id) > -1)
            .filter((row) => row.disableDelete).length === 0 && (
            <Tooltip title="Delete">
              <IconButton
                onClick={(event) =>
                  handleDeleteClick(
                    event,
                    rows
                      .filter((row) => selected.indexOf(row.id) > -1)
                      .map((row) => row.id)
                  )
                }
              >
                <DeleteIcon />
                <Typography variant="body2">削除</Typography>
              </IconButton>
            </Tooltip>
          )}
        {numSelected === 0 && !disableAdd && (
          <Tooltip title="Add">
            <IconButton onClick={handleAddClick}>
              <AddCircleRounded />
              <Typography variant="body2">追加</Typography>
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // 全選択チェック処理
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // 全選択
      const newSelected = rows?.map((n) => n.id);
      updateSelection(newSelected);
    } else {
      // 全解除
      updateSelection([]);
    }
  };

  // 行選択処理
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    updateSelection(newSelected);
  };

  // 選択行を設定する
  const updateSelection = (newSelected) => {
    setSelected(newSelected);
    if (!!_setSelected) {
      _setSelected(newSelected);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  let visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rows, rowsPerPage]
  );
  if (disablePagination) visibleRows = rows;

  // ダイアログ関連↓
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleEditClick = (e, rowData) => {
    if (enableKakuninButton) {
      setDialogTitle(listName + "の確認");
    } else {
      setDialogTitle(listName + "の編集");
    }
    setDialogOpen(true);
    setTargetData(rowData);
    e.stopPropagation();
  };
  const handleAddClick = () => {
    setDialogTitle(listName + "の作成");
    setDialogOpen(true);
    setTargetData({});
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const postSubmit = (result) => {
    if (result?.status === 200) {
      handleDialogClose();
      fetchData();
      postSubmitHandler();
    }
  };
  // ダイアログ関連↑

  //　削除関連↓
  const [alertOpen, setAlertOpen] = useState(false);
  const [delIds, setDelIds] = useState([]);
  const handleDeleteClick = (e, ids) => {
    setDelIds(ids);
    setAlertOpen(true);
    e.stopPropagation();
  };
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const handleDelete = () => {
    const deleteData = async () => {
      await connect(() => {
        return httpClient.post(deletePath, delIds);
      }, "削除しました");
      console.log("post end", delIds);
      fetchData();
      setDelIds([]);
      setSelected([]);
      if (!!_setSelected) {
        _setSelected([]);
      }
      handleAlertClose();
    };
    deleteData();
  };
  //　削除関連↑

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {(!disableAdd || !disableDelete) && (
          <EnhancedTableToolbar numSelected={selected?.length} />
        )}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              headCells={headCells}
              disableDelete={disableDelete}
            />
            <TableBody>
              {visibleRows?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={
                      !disableDelete
                        ? (event) => handleClick(event, row.id)
                        : () => false
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox" key={row.id + "checkbox"}>
                      {!disableDelete && (
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      )}
                    </TableCell>

                    {headCells?.map((headCell) => {
                      return (
                        headCell.visible && (
                          <TableCell
                            scope="row"
                            padding="none"
                            key={row.id + headCell.id}
                          >
                            {row[headCell.id]}
                          </TableCell>
                        )
                      );
                    })}
                    <TableCell key={row.id + "operation"}>
                      {!disableEdit &&
                        !enableKakuninButton &&
                        !row.disableEdit && (
                          <Tooltip title="編集" sx={{ marginRight: "1rem" }}>
                            <Edit
                              onClick={(event) => handleEditClick(event, row)}
                            />
                          </Tooltip>
                        )}

                      {!disableDelete && !row.disableDelete && (
                        <Tooltip title="削除">
                          <DeleteIcon
                            onClick={(event) =>
                              handleDeleteClick(event, [row.id])
                            }
                          />
                        </Tooltip>
                      )}
                      {enableKakuninButton && (
                        <Button
                          onClick={(event) => handleEditClick(event, row)}
                          size="small"
                          sx={{ margin: "0" }}
                        >
                          確認
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                  key="empty-row"
                >
                  <TableCell colSpan={6} key="empty-cell" />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!disablePagination && (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      {/* 編集ダイアログ */}
      {!disableDialog && (
        <FormDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          title={dialogTitle}
          maxWidth={dialogWidth}
        >
          {editDialogContent({
            targetData: targetData,
            ...fetchResult,
            ...dialogProps,
            closeHandler: postSubmit,
            readOnly: readOnlyDialog,
          })}
        </FormDialog>
      )}
      {/* 削除確認Alertダイアログ */}
      <Dialog open={alertOpen} onClose={handleAlertClose}>
        <DialogContent>
          <DialogTitle>
            選択されたデータを削除します。よろしいですか？
          </DialogTitle>
          <DialogContentText>※この操作は元に戻せません。</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="warning">
            削除
          </Button>
          <Button onClick={handleAlertClose}>キャンセル</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
