import Constants from "./Constants";

const getConfirmerListHead = () => {
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
      visible: false,
    },
    {
      id: "department",
      numeric: false,
      disablePadding: true,
      label: "部署",
      visible: true,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "氏名",
      visible: true,
    },
    {
      id: "eMailAddress",
      numeric: false,
      disablePadding: true,
      label: "メールアドレス",
      visible: true,
    },
    {
      id: "isApprovedLabel",
      numeric: false,
      disablePadding: true,
      label: "承認",
      visible: true,
    },
    {
      id: "authorityLabel",
      numeric: false,
      disablePadding: true,
      label: "種別",
      visible: true,
    },
  ];
  return headCells;
};

export const confirmerDataMapper = (data) => {
  return data?.data?.map((row) => {
    // 承認済みラベルの設定
    let isApprovedLabel;
    if (row.authority === Constants.CONFIRMER_AUTHORITY_TYPE.EDIT) {
      isApprovedLabel = "ー";
    } else {
      isApprovedLabel = row.isApproved ? "済" : "";
    }

    return {
      ...row,
      disableDelete: row.isCreatedByAuditorOrClient,
      isApprovedLabel: isApprovedLabel,
    };
  });
};
export default getConfirmerListHead;
