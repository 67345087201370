/* eslint-disable react-hooks/exhaustive-deps */
import { Button, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import useApi, { httpClient, useDownloadFile } from "../../hooks/useApi";
import MESSAGES from "../../utils/Messages";
import FormDialog from "../FormDialog";

// バリデーション定義
const schema = z.object({
  targetPeriodId: z.string().min(1, MESSAGES.REQUIRED),
  companyId: z.string().min(1, MESSAGES.REQUIRED),
});

const DownloadConfirmationResultDialog = ({
  open,
  clientId,
  closeHandler = () => {},
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isDirty },
    watch,
  } = useForm({
    mode: "all",
    resolver: zodResolver(schema),
  });
  const { connect } = useApi();
  const { downloadFile } = useDownloadFile();

  // 初期表示用データ取得
  const [fetchResult, setFetchResult] = useState();
  const fetchData = async () => {
    const result = await connect(() => {
      return httpClient.get(
        `../../api/auditor/GetConfirmationResultExcelDownloadOptions?clientId=${clientId}`
      );
    });
    setFetchResult(result?.data);
    setValue("targetPeriodId", result?.data?.targetPeriodList[0]?.id);
    setValue("companyId", result?.data?.companyList[0]?.id);
  };
  useEffect(() => {
    fetchData();
  }, [clientId]);

  // API POST
  const onSubmit = async (data) => {
    const queryParams = new URLSearchParams({
      clientId: clientId,
      targetPeriodId: data.targetPeriodId,
      companyId: data.companyId,
    }).toString();
    await downloadFile(
      `/api/auditor/DownloadConfirmationResultExcelForCompany?${queryParams}`
    );
    closeHandler();
  };

  return (
    <FormDialog
      open={open}
      title="確認結果一覧ダウンロード"
      onClose={closeHandler}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="targetPeriodId"
          control={control}
          render={({ field }) => (
            <TextField
              label="対象期間"
              select
              {...field}
              required
              error={!!errors.targetPeriodId}
              helperText={
                errors.targetPeriodId ? errors.targetPeriodId.message : ""
              }
            >
              {fetchResult?.targetPeriodList?.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.title}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <br />
        <Controller
          name="companyId"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              label="対象会社"
              required
              select
              {...field}
              error={!!errors.companyId}
              helperText={errors.companyId ? errors.companyId.message : ""}
            >
              {fetchResult?.companyList?.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.companyName}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <br />
        <Button type="submit">ダウンロード</Button>
      </form>
    </FormDialog>
  );
};

export default DownloadConfirmationResultDialog;
