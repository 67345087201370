import MuiRouterLink from "../components/MuiRouterLink";

const getCompanyListHead = (visibleOptional) => {
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
      visible: false,
    },
    {
      id: "companyCode",
      numeric: false,
      disablePadding: true,
      label: "会社コード",
      visible: true,
      minWidth: "4rem",
    },
    {
      id: "companyAbbreviation",
      numeric: false,
      disablePadding: true,
      label: "会社略称",
      visible: visibleOptional,
      minWidth: "4rem",
    },
    {
      id: "companyNameLink",
      numeric: false,
      disablePadding: true,
      label: "会社名",
      visible: true,
      minWidth: "5rem",
    },
    {
      id: "representativeName",
      numeric: false,
      disablePadding: true,
      label: "代表者氏名",
      visible: true,
      minWidth: "5rem",
    },
    {
      id: "representativeTitle",
      numeric: false,
      disablePadding: true,
      label: "代表者肩書",
      visible: true,
      minWidth: "5rem",
    },
    {
      id: "postCode",
      numeric: false,
      disablePadding: true,
      label: "郵便番号",
      visible: visibleOptional,
      minWidth: "5rem",
    },
    {
      id: "prefecture",
      numeric: false,
      disablePadding: true,
      label: "都道府県",
      visible: visibleOptional,
      minWidth: "5rem",
    },
    {
      id: "cityWardTownVillage",
      numeric: false,
      disablePadding: true,
      label: "市区町村",
      visible: visibleOptional,
      minWidth: "5rem",
    },
    {
      id: "restAddress",
      numeric: false,
      disablePadding: true,
      label: "番地・ビル名など",
      visible: visibleOptional,
      minWidth: "5rem",
    },
    {
      id: "companyNameEnglish",
      numeric: false,
      disablePadding: true,
      label: "Corporate Name（English）",
      visible: visibleOptional,
      minWidth: "5rem",
    },
    {
      id: "addressEnglish",
      numeric: false,
      disablePadding: true,
      label: "Address（English）",
      visible: visibleOptional,
      minWidth: "5rem",
    },
    {
      id: "countryEnglish",
      numeric: false,
      disablePadding: true,
      label: "Country（English）",
      visible: visibleOptional,
      minWidth: "5rem",
    },
    {
      id: "postCodeEnglish",
      numeric: false,
      disablePadding: true,
      label: "Post Code（English）",
      visible: visibleOptional,
      minWidth: "5rem",
    },
    {
      id: "tel",
      numeric: false,
      disablePadding: true,
      label: "電話番号",
      visible: visibleOptional,
      minWidth: "5rem",
    },
    {
      id: "registerStatusLabel",
      numeric: false,
      disablePadding: true,
      label: "入力ステータス",
      visible: true,
      minWidth: "5rem",
    },
  ];
  return headCells;
};

export const companyDataMapper = (data, role) => {
  return data?.data?.map((row) => ({
    ...row,
    companyNameLink: (
      <MuiRouterLink to={`/${role}/company/${row.id}`} text={row.companyName} />
    ),
    disableDelete: row.clientId === row.id,
    disableEdit: row.clientId === row.id,
  }));
};

export default getCompanyListHead;
