/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { saveAs } from "file-saver";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { loadingActions, messageActions } from "../store/store";

export let httpClient = axios.create({
  headers: {
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: "0",
  },
});

function useApi() {
  const dispatch = useDispatch();

  const connect = useCallback(
    async (axiosFunc, success, throwError = false) => {
      const loadingId = crypto.randomUUID();
      dispatch(loadingActions.start(loadingId));
      let result = await axiosFunc().catch((err) => {
        return err.response;
      });
      if (throwError) {
        if (result?.status !== 200) {
          throw new Error(result?.status + ":" + result?.data);
        }
      }
      if (result?.status !== 200) {
        dispatch(
          messageActions.setError(
            result?.status + "：" + result?.statusText + "\n" + result?.data
          )
        );
      } else {
        if (!!success) {
          dispatch(messageActions.setSuccess(success));
        }
      }
      dispatch(loadingActions.end(loadingId));
      return result;
    },
    [dispatch]
  );

  return { connect };
}

export const useDownloadFile = () => {
  const dispatch = useDispatch();

  const downloadFile = useCallback(
    async (url) => {
      const loadingId = crypto.randomUUID();
      dispatch(loadingActions.start(loadingId));
      try {
        const response = await httpClient.get(url, {
          responseType: "blob",
          params: {
            t: new Date().getTime(), // キャッシュ防止のためにタイムスタンプをクエリパラメータに追加
          },
        });
        const contentDisposition = response.headers["content-disposition"];
        const fileName = getFileNameFromHeader(contentDisposition) || "error";

        const blob = new Blob([response.data], { type: response.data.type });
        saveAs(blob, fileName);
      } catch (error) {
        // エラーレスポンスの Blob をテキストに変換
        const errorText = await error.response.data.text();
        dispatch(
          messageActions.setError(
            error?.response?.status +
              "：" +
              error?.response?.statusText +
              "\n" +
              errorText
          )
        );
      } finally {
        dispatch(loadingActions.end(loadingId));
      }
    },
    [dispatch]
  );

  return { downloadFile };
};

const getFileNameFromHeader = (contentDisposition) => {
  if (!contentDisposition) return null;
  const fileNameMatch = contentDisposition.match(/filename\*=UTF-8''([^;\n]*)/);
  if (fileNameMatch && fileNameMatch[1]) {
    return decodeURIComponent(fileNameMatch[1].replace(/['"]/g, ""));
  }
  return null;
};

export default useApi;
